import { MenuItem, Select } from "@mui/material";
import { Form, Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { UserProfile } from "../../config/flow/user";

import { IRemoteData } from "../../utils/RemoteData";
import { passwordSchema } from "./schema";
import PasswordIcon from "../../assets/images/eye-ico.svg";
import "./style.scss";
import { useTogglePasswordVisibility } from "../../utils/useTogglePasswordVisibility";
import { userChangePasswordRoutine } from "../../redux/routines/userRoutine";
import { useSnackbar } from "notistack";

type Props = {};

interface IState {
	user: { user: IRemoteData<UserProfile>; userUpdate: IRemoteData };
}

export const ProfileSecurityPage = (props: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { passwordVisibility, handlePasswordVisibility } = useTogglePasswordVisibility();

	const user = useSelector((state: IState) => state.user.user);

	const userChangePassword = useSelector((state: any) => state.user.userChangePassword);

	useEffect(() => {
		if (userChangePassword.isSuccess) {
			enqueueSnackbar("Пароль изменен", { variant: "success" });
			dispatch(userChangePasswordRoutine.fulfill());
			navigate(-1);
		}
	}, [userChangePassword]);

	return (
		<>
			<div>
				<h1>Настройки безопасности</h1>
				<Formik
					initialValues={{
						email: user?.data?.email,
						password: "",
						confirmPassword: "",
					}}
					validationSchema={passwordSchema}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values) => {
						console.log(values);
						dispatch(userChangePasswordRoutine(values));
					}}
				>
					{({ values, errors, touched }) => (
						<Form className="profile-form">
							<div>
								<Field id="email" name="email" placeholder="E-mail" component={Input} />
								<Field
									id="password"
									name="password"
									type={passwordVisibility ? "password" : "text"}
									placeholder="Пароль"
									component={Input}
									inputProps={{ rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} /> }}
								/>
								<Field
									id="confirmPassword"
									name="confirmPassword"
									type={passwordVisibility ? "password" : "text"}
									placeholder="Подтверждение пароля"
									component={Input}
									inputProps={{ rightIcon: <img src={PasswordIcon} onClick={handlePasswordVisibility} /> }}
								/>

								<Button type="submit" style={{ minWidth: 244 }}>
									Сохранить
								</Button>
								{Object.values(errors).length > 0 && (
									<div className="form-errors">
										{Object.values(errors).map((msg, index) => (
											<p key={`err_${index}`}>{msg}</p>
										))}
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};
