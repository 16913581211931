import { call, takeLatest, put, select, race, take } from "redux-saga/effects";

import Api from "../api";
import {
  userRefreshRoutine,
  userLogoutRoutine,
} from "../redux/routines/userRoutine";
import RemoteData from "./RemoteData";

const getAuth = (state: any) => state.user.auth;

const createSaga = (
  routine: any,
  method: "GET" | "POST",
  action: string,
  options?: any
) => {
  function* saga(_action: any) {
    yield put(routine.request());
    let response: { error?: any; success?: any } = {};
    const auth: RemoteData<any> = yield select(getAuth);
    response = yield call(() =>
      Api.request(action, method, _action?.payload, auth, options)
    );

    if (response?.error === "wrong_token") {
      if (auth?.data) {
        const { userId, refreshToken } = auth?.data;

        yield put(
          userRefreshRoutine.trigger({
            userId,
            refreshToken,
          })
        );
        const { success } = yield race({
          success: take(userRefreshRoutine.SUCCESS),
          fail: take(userRefreshRoutine.FAILURE),
        });
        if (success) {
          yield put(_action);
        } else {
          yield put(userLogoutRoutine.trigger());
        }
      }
    } else if (response?.error === "no_token") {
      yield put(userLogoutRoutine.trigger());
    }

    let isSuccess = false;

    if (response?.success) {
      isSuccess = true;
    }

    if (isSuccess) {
      yield put(routine.success(response));
    } else {
      yield put(routine.failure(response));
    }

    if (isSuccess && options?.onSuccess) {
      yield options.onSuccess(response, _action?.payload);
    }
  }

  return takeLatest(routine.TRIGGER, saga);
};

export { createSaga };
