import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import {
  anamnezDiagnozRoutine,
  anamnezLifeRoutine,
  anamnezRoutine,
  userAnamnezRoutine,
} from "../routines/anamnezRoutine";

const persistConfig = {
  key: "anamnez",
  blacklist: ["anamnez", "userAnamnez"],
};

export default createPersistedReducer(
  combineReducers({
    anamnez: createReducer(anamnezRoutine, "anamnez"),
    anamnezLife: createReducer(anamnezLifeRoutine, "anamnezLife"),
    anamnezDiagnoz: createReducer(anamnezDiagnozRoutine, "anamnezDiagnoz"),

    userAnamnez: createReducer(userAnamnezRoutine, "userAnamnez"),
  }),
  persistConfig
);
