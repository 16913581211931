import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({
	secretKey: "b789sCj82*_-b5VcXjA=hgBT3F#MA*Jq",
});

export const createPersistedReducer = (reducer: any, persistConfig: any) =>
	persistReducer(
		{
			storage,
			transforms: [encryptor],
			...persistConfig,
		},
		reducer
	);
