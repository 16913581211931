import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { AnamnezItem } from "../Anamnez/AnamnezItem";
import {
  hospitalAddRoutine,
  hospitalRoutine,
  hospitalStatusRoutine,
} from "../../redux/routines/hospitalRoutine";
import Scrollbar from "react-scrollbars-custom";
import { CustomScrollbar } from "../../components/CustomScrollbar";
import { Field, Form, Formik } from "formik";
import { Input } from "../../components/Input";
import { hospitalSchema } from "./schema";

type Props = {};

type HospitalType = {
  [key: string]: any;
};

export const HospitalsPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hospitals = useSelector((state: any) => state.hospital.hospitals);
  const hospitalStatus = useSelector(
    (state: any) => state.hospital.hospitalStatus
  );
  const hospitalAdd = useSelector((state: any) => state.hospital.hospitalAdd);

  const [showAddForm, setShowAddForm] = useState(false);

  const [hospitalsData, setHospitalsData] = useState<HospitalType>({});

  const handleStatus = (hospitalId, status) => {
    dispatch(hospitalStatusRoutine({ hospitalId, status }));
  };

  const handleShowAddForm = () => setShowAddForm(!showAddForm);

  useEffect(() => {
    setShowAddForm(false);
    dispatch(hospitalRoutine());
  }, [hospitalAdd]);

  useEffect(() => {
    if (hospitals.isSuccess && hospitals?.data?.items) {
      let hospitalsData: any = {};

      hospitals?.data?.items.map((item) => {
        if (!hospitalsData[item.status]) hospitalsData[item.status] = [];
        hospitalsData[item.status].push(item);
      });

      setHospitalsData(hospitalsData);
    }
  }, [hospitals]);

  useEffect(() => {
    dispatch(hospitalRoutine());
  }, [hospitalStatus]);

  return (
    <div className="block-list hospitals-list">
      <div className="block">
        <h2>Зарегистрированные</h2>
        <div className="list">
          <CustomScrollbar>
            {hospitalsData?.active &&
              hospitalsData?.active.map((item) => {
                let glav = [
                  item.glavLastname,
                  item.glavName,
                  item.glavSecondname,
                ].join(" ");
                return (
                  <AnamnezItem
                    key={`active-${item.id}`}
                    item={{
                      text: `${item.name}<br>Главный врач: ${glav}<br>Адрес: ${item.address}`,
                    }}
                    rightBtn={
                      <a
                        className="btn-link"
                        onClick={() => handleStatus(item.id, "deleted")}
                      >
                        Удалить
                      </a>
                    }
                  />
                );
              })}
          </CustomScrollbar>
        </div>
        {!showAddForm && (
          <Button
            variant="yellow"
            style={{ alignSelf: "flex-start" }}
            onClick={handleShowAddForm}
          >
            Добавить поликлинику
          </Button>
        )}
      </div>
      <div className="block">
        <h2>Удаленные</h2>
        <div className="list">
          {showAddForm ? (
            <CustomScrollbar>
              <Formik
                initialValues={{
                  name: "",
                  glavName: "",
                  glavLastname: "",
                  glavSecondname: "",
                  region: "",
                  city: "",
                  street: "",
                  house: "",
                  korpus: "",
                  stroenie: "",
                }}
                validationSchema={hospitalSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                  dispatch(hospitalAddRoutine(values));
                }}
              >
                {({ errors, touched }) => (
                  <Form className="hospital-add-form">
                    <div className="field-block">
                      <label>Главный врач</label>
                      <Field
                        name="glavLastname"
                        placeholder="Фамилия"
                        component={Input}
                      />
                      <Field
                        name="glavName"
                        placeholder="Имя"
                        component={Input}
                      />
                      <Field
                        name="glavSecondname"
                        placeholder="Отчество"
                        component={Input}
                        style={{ marginBottom: 0 }}
                      />
                    </div>
                    <div className="field-block">
                      <Field
                        name="name"
                        placeholder="Название учреждения"
                        component={Input}
                        style={{ marginBottom: 0 }}
                      />
                    </div>
                    <div className="field-block">
                      <label>Адрес</label>
                      <Field
                        name="region"
                        placeholder="Область"
                        component={Input}
                      />
                      <Field
                        name="city"
                        placeholder="Город"
                        component={Input}
                      />
                      <Field
                        name="street"
                        placeholder="Улица"
                        component={Input}
                      />
                      <div style={{ display: "flex", gap: 34 }}>
                        <Field
                          name="house"
                          placeholder="Дом"
                          component={Input}
                          style={{ marginBottom: 0 }}
                        />
                        <Field
                          name="korpus"
                          placeholder="Корпус"
                          component={Input}
                          style={{ marginBottom: 0 }}
                        />
                        <Field
                          name="stroenie"
                          placeholder="Строение"
                          component={Input}
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </div>
                    {Object.values(errors).length > 0 && (
                      <div className="form-errors" style={{ marginBottom: 28 }}>
                        {Object.values(errors).map((msg, index) => (
                          <p key={`err_${index}`}>{msg}</p>
                        ))}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button type="submit" variant="yellow">
                        Добавить поликлинику
                      </Button>
                      <Button onClick={handleShowAddForm}>
                        Отменить добавление
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CustomScrollbar>
          ) : (
            <CustomScrollbar>
              {hospitalsData?.deleted &&
                hospitalsData?.deleted.map((item) => {
                  let glav = [
                    item.glavLastname,
                    item.glavName,
                    item.glavSecondname,
                  ].join(" ");
                  return (
                    <AnamnezItem
                      key={`deleted-${item.id}`}
                      item={{
                        text: `${item.name}<br>Главный врач: ${glav}<br>Адрес: ${item.address}`,
                      }}
                      rightBtn={
                        <a
                          className="btn-link"
                          onClick={() => handleStatus(item.id, "active")}
                        >
                          Восстановить
                        </a>
                      }
                    />
                  );
                })}
            </CustomScrollbar>
          )}
        </div>
      </div>
    </div>
  );
};
