import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  matchPath,
  matchRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { hospitalRoutine } from "../../redux/routines/hospitalRoutine";
import { userLogoutRoutine } from "../../redux/routines/userRoutine";
import SettingsIco from "../../assets/images/settings-ico.svg";
import ExitIco from "../../assets/images/exit-ico.png";
import "./style.scss";

type Props = {
  back?: boolean;
  right?: boolean;
};

export const Header = ({ back = false, right = false }: Props) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showBack = location.pathname != "/";
  const colored = ["/forgot", "/register"].indexOf(location.pathname) === -1;
  const handleBack = () => {
    navigate(-1);
  };

  const user = useSelector((state: any) => state.user.user);
  const hospitals = useSelector((state: any) => state.hospital.hospitals);

  const [hospitalsData, setHospitalsData] = useState([]);

  const handleLogout = () => {
    dispatch(userLogoutRoutine());
  };

  useEffect(() => {
    dispatch(hospitalRoutine());
  }, []);

  useEffect(() => {
    if (hospitals.isSuccess && hospitals?.data?.items) {
      let hospitalsData: any = {};

      hospitals?.data?.items.map((item) => {
        hospitalsData[item.id] = item.name;
      });

      setHospitalsData(hospitalsData);
    }
  }, [hospitals]);

  let backText = "Вернуться назад";
  if (location.pathname.indexOf("/doctors") !== -1) backText = "Врачи";
  if (location.pathname.indexOf("/admins") !== -1) backText = "Администраторы";
  if (location.pathname.indexOf("/hospitals") !== -1) backText = "Поликлиники";

  return (
    <header className={classNames({ back: back && !right })}>
      {back && (
        <div className="left-part">
          {showBack && (
            <Link to="#" className="back-btn" onClick={handleBack}>
              {backText}
            </Link>
          )}
        </div>
      )}
      {right && (
        <div className="header-right">
          <div className="user-inner">
            <p className="text title">
              {user?.data?.lastname} {user?.data?.name} {user?.data?.secondname}
            </p>
            <p className="text">Администратор</p>
            <p className="text">{hospitalsData[user?.data?.hospital]}</p>
          </div>
          <div className="header-btns">
            <Link to="/profile" className="btn">
              <img src={SettingsIco} alt="" />
            </Link>
            <Link to="/profile" className="btn" onClick={handleLogout}>
              <img src={ExitIco} alt="" />
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};
