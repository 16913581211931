import { all } from "redux-saga/effects";

import userSaga from "./userSaga";
import hospitalSaga from "./hospitalSaga";
import anamnezSaga from "./anamnezSaga";
import visitsSaga from "./visitsSaga";

export default function* rootSaga() {
  yield all([userSaga(), hospitalSaga(), anamnezSaga(), visitsSaga()]);
}
