import {
  visitCommentsRoutine,
  visitSaveRoutine,
} from "./../routines/visitsRoutine";
import { createSaga } from "../../utils/createSaga";
import { visitsAddRoutine, visitsRoutine } from "../routines/visitsRoutine";

export default function* anamnezSaga() {
  yield createSaga(visitsRoutine, "GET", "api/visits/");
  yield createSaga(visitsAddRoutine, "POST", "api/visits/");

  yield createSaga(visitSaveRoutine, "POST", "api/visit/save", {
    withFile: true,
  });

  yield createSaga(visitCommentsRoutine, "GET", "api/visit/comments");
}
