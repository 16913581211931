import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import {
  hospitalAddRoutine,
  hospitalRoutine,
  hospitalStatusRoutine,
} from "../routines/hospitalRoutine";

const persistConfig = {
  key: "hospital",
  blacklist: ["hospitalStatus", "hospitalAdd"],
};

export default createPersistedReducer(
  combineReducers({
    hospitals: createReducer(hospitalRoutine, "hospitals", true),
    hospitalStatus: createReducer(hospitalStatusRoutine),
    hospitalAdd: createReducer(hospitalAddRoutine, "hospitalAdd"),
  }),
  persistConfig
);
