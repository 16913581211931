import { combineReducers } from "redux";

import user from "./userReducer";
import hospital from "./hospitalReducer";
import anamnez from "./anamnezReducer";
import visits from "./visitsReducer";

export default combineReducers({
  user,
  hospital,
  anamnez,
  visits
});
