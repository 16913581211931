import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Header } from "./components/Header";
import { HomePage } from "./pages/Home";
import { LoginPage } from "./pages/Login";
import { NotFoundPage } from "./pages/NotFound";
import { ProfilePage } from "./pages/Profile";
import { userStatusRoutine } from "./redux/routines/userRoutine";
import store, { persistor } from "./redux/store";

import "./App.scss";
import { ProfileSecurityPage } from "./pages/Profile/ProfileSecurity";
import { SnackbarProvider } from "notistack";
import { ForgotPage } from "./pages/Forgot";
import { PatientDetailPage } from "./pages/PatientDetail";
import { RegisterPage } from "./pages/Register";
import { DoctorsPage } from "./pages/Doctors";
import { AdminsPage } from "./pages/Admins";
import { HospitalsPage } from "./pages/Hospitals";

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppContainer = () => {
  const auth = useSelector((state: any) => state.user.auth);
  const userStatus = useSelector((state: any) => state.user.userStatus);
  const [showHeader, setShowHeader] = useState(false);
  const [showLeftSide, setShowLeftSide] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(userStatusRoutine());
  }, []);

  useEffect(() => {
    if (userStatus.isFailure) {
      navigate("/login");
    }
  }, [userStatus]);

  useEffect(() => {
    if (["/login", "/forgot", "/register"].indexOf(location.pathname) === -1) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }

    if (location.pathname.indexOf("/profile") === -1) {
      setShowLeftSide(true);
    } else {
      setShowLeftSide(false);
    }
  }, [navigate, location]);

  return (
    <>
      <main>
        <div className="right-side">
          {((auth.isSuccess && userStatus.isSuccess) || showHeader) && (
            <Header
              back={
                [
                  "/doctors",
                  "/admins",
                  "/hospitals",
                  "/profile",
                  "/profile/security",
                ].indexOf(location.pathname) !== -1
              }
              right
            />
          )}
          {["/forgot", "/register"].indexOf(location.pathname) !== -1 && (
            <Header back />
          )}
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/security"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <ProfileSecurityPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/doctors"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <DoctorsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admins"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <AdminsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hospitals"
              element={
                <ProtectedRoute user={auth.isSuccess}>
                  <HospitalsPage />
                </ProtectedRoute>
              }
            />
            <Route path="/forgot" element={<ForgotPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </main>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={3}
          hideIconVariant
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <AppContainer />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
