/* eslint-disable camelcase */
const INITIAL = "STATE_INITIAL";
const REQUEST = "STATE_REQUEST";
const SUCCESS = "STATE_SUCCESS";
const FAILURE = "STATE_FAILURE";

export interface IRemoteData<T = null> {
	data: T;
	error?: ApiError;
	isSuccess: boolean;
	isFailure: boolean;
	isRequest: boolean;
	isInitial: boolean;
	readableError: boolean;
	errorCode: number;
}

export interface ApiResponse {
	error: {
		message: string;
		code: number;
	};
}

export interface ApiError {
	error: string | ApiResponse;
	error_more: string;
	error_readable: string;
	error_field: string | null;
}

export default class RemoteData<T extends { data?: any }> implements IRemoteData<T> {
	state: string;
	stateData: T | null;
	stateError: ApiError | null;
	stateErrorCode: number;
	useCache: boolean;
	isReadableError: boolean;

	constructor({
		state,
		stateData,
		stateError,
		stateErrorCode,
		useCache,
		isReadableError,
	}: {
		state?: string;
		stateData?: T;
		stateError?: ApiError;
		stateErrorCode?: number;
		useCache?: boolean;
		isReadableError?: boolean;
	} = {}) {
		this.state = state || INITIAL;
		this.stateData = stateData || null;
		this.stateError = stateError || null;
		this.stateErrorCode = stateErrorCode || 0;
		this.useCache = useCache || false;
		this.isReadableError = isReadableError || false;
	}

	get data(): T {
		return this.stateData?.data || this.stateData;
	}

	get error() {
		return this.stateError || undefined;
	}

	get errorMessage(): any {
		let mess = "";
		if (this.stateError?.error && typeof this.stateError?.error !== "string" && "error" in this.stateError?.error) mess = this.stateError?.error?.error?.message;
		return this.stateError?.error_readable || this.stateError?.error_more || mess || this.stateError?.error;
	}

	get isSuccess() {
		return this.state === SUCCESS;
	}

	get isFailure() {
		return this.state === FAILURE;
	}

	get isRequest() {
		return this.state === REQUEST;
	}

	get isInitial() {
		return this.state === INITIAL;
	}

	get readableError() {
		return this.isReadableError;
	}

	get errorCode() {
		return this.stateErrorCode;
	}

	makeNewInstance(opts: any) {
		if (this.useCache) {
			return new RemoteData({
				useCache: true,
				stateData: this.stateData,
				...opts,
			});
		}

		return new RemoteData(opts);
	}

	success(stateData: T) {
		return this.makeNewInstance({
			state: SUCCESS,
			stateData,
		});
	}

	failure(stateError: { error: string | ApiResponse; error_more: string; error_readable: string; error_field: string | null }) {
		let code = 0;
		if (this.stateError?.error && typeof this.stateError?.error !== "string" && "error" in this.stateError?.error) code = this.stateError?.error?.error?.code;
		return this.makeNewInstance({
			state: FAILURE,
			isReadableError: !!stateError.error_readable,
			stateError,

			stateErrorCode: code,
		});
	}

	request() {
		return this.makeNewInstance({
			state: REQUEST,
		});
	}
}
