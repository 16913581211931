export const config = {
  //domen: "https://herz2.seoven.ru:3000/",
  domen: "https://idukvrachu.gw2.seoven.ru:3000/",
  //domen: "http://192.168.0.85:3000/",
  policy: "https://google.com",
};

export const ANAMNEZ_LIFE = 1;
export const ANAMNEZ_HEALTH = 24;
export const ANAMNEZ_DIAGNOZ = 285;
