import { Autocomplete, Grid, List, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import { ANAMNEZ_HEALTH } from "../../config/config";
import { anamnezRoutine } from "../../redux/routines/anamnezRoutine";
import { visitsRoutine } from "../../redux/routines/visitsRoutine";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { Input } from "../../components/Input";
import throttle from "lodash/throttle";
import {
  userSearchRoutine,
  usersGetRoutine,
} from "../../redux/routines/userRoutine";
import { Box } from "@mui/system";
import _, { debounce } from "lodash";
import { useStateWithCallbackLazy } from "../../utils/useStateCallback";

type Props = {};
type UserType = {
  [key: string]: any;
};

export const HomePage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user.user);
  const usersGet = useSelector((state: any) => state.user.usersGet);

  const [users, setUsers] = useState<UserType>({});

  useEffect(() => {
    dispatch(usersGetRoutine({ role: ["admin", "doctor"] }));

    return () => {
      dispatch(usersGetRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    let u = {};

    usersGet.data?.items.map((item) => {
      if (!u[item.role.code]) u[item.role.code] = [];
      u[item.role.code].push(item);
    });
    setUsers(u);
  }, [usersGet]);

  let doctorsCount = 0;
  let adminCount = 0;
  if (users.doctor) {
    let usersCount = users.doctor.filter((item) => item.status == "nonactive");
    doctorsCount = usersCount.length;
  }
  if (users.admin) {
    let usersCount = users.admin.filter((item) => item.status == "nonactive");
    adminCount = usersCount.length;
  }

  return (
    <div className="index-block">
      <h2>Администратор</h2>
      <div className="index-menu">
        <Button onClick={() => navigate("/doctors")}>
          Врачи{" "}
          {doctorsCount > 0 && <span className="count">+{doctorsCount}</span>}
        </Button>
        <Button onClick={() => navigate("/admins")}>
          Администраторы{" "}
          {adminCount > 0 && <span className="count">+{adminCount}</span>}
        </Button>
        <Button onClick={() => navigate("/hospitals")}>Поликлиники</Button>
      </div>
    </div>
  );
};
