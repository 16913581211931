import {
  userRoleStatusRoutine,
  usersGetRoutine,
} from "./../routines/userRoutine";
import { put, call, takeLeading } from "redux-saga/effects";

import {
  userChangePasswordRoutine,
  userForgotRoutine,
  userLoginRoutine,
  userLogoutRoutine,
  userPincodeLoginRoutine,
  userPincodeRoutine,
  userRefreshRoutine,
  userRegisterRoutine,
  userRoutine,
  userSearchRoutine,
  userStatusRoutine,
  userUpdateRoutine,
} from "../routines/userRoutine";
import { createSaga } from "../../utils/createSaga";
import { tokenRoutine } from "../routines/tokenRoutine";
import { persistor } from "../store";

function* logout() {
  console.log("logout");
  yield put(userLogoutRoutine.fulfill());
  yield put(userRefreshRoutine.fulfill());
  yield put(tokenRoutine.fulfill());
  yield put(userStatusRoutine.fulfill());
  yield put(userPincodeRoutine.fulfill());
  yield put(userRoutine.fulfill());
  yield call(() => persistor.pause());
  // yield put({ type: "RESET" });

  yield call(() => {
    window.localStorage.clear();
    persistor.persist();
  });
  // yield call(() => {
  //   //console.log("redirect");
  //   //window.location.href = "/";
  // });
}

export default function* userSaga() {
  //yield takeLeading(userLogoutRoutine.TRIGGER, logout);
  yield createSaga(userLoginRoutine, "POST", "api/auth/login", {
    *onSuccess(response: any) {
      yield put(tokenRoutine.success(response));
      yield put(userStatusRoutine.trigger());
      //yield put(userStatusRoutine.trigger());
    },
  });

  yield createSaga(userRegisterRoutine, "POST", "api/auth/register");

  yield createSaga(userLogoutRoutine, "POST", "api/auth/logout", {
    onSuccess: logout,
  });

  yield createSaga(userForgotRoutine, "POST", "api/auth/forgot");

  yield createSaga(userRefreshRoutine, "POST", "api/auth/refresh", {
    *onSuccess(response: any, payload: any) {
      yield put(tokenRoutine.success(response));

      if (payload.routine) {
        yield put(payload.routine.trigger({ ...payload.data }));
      }
      yield put(userStatusRoutine.trigger());
    },
  });

  yield createSaga(userStatusRoutine, "GET", "api/auth/status", {
    *onSuccess(response: any) {
      yield put(userRoutine.trigger());
    },
  });

  yield createSaga(userRoutine, "GET", "api/user/getInfo");

  yield createSaga(userUpdateRoutine, "POST", "api/user/update", {
    *onSuccess(response: any) {
      yield put(userRoutine.trigger());
    },
  });

  yield createSaga(
    userChangePasswordRoutine,
    "POST",
    "api/user/changePassword"
  );

  yield createSaga(userSearchRoutine, "GET", "api/user/search");
  yield createSaga(usersGetRoutine, "GET", "api/user/getUsers");
  yield createSaga(userRoleStatusRoutine, "POST", "api/user/setStatus");
}
