import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import "./style.scss";

type Props = {
  children;
};

export const CustomScrollbar = ({ children }: Props) => {
  const scrollbarRef = useRef(null);

  const [scrollWidth, setScrollWidth] = useState(60);

  useEffect(() => {
    console.log("asd", scrollbarRef.current?.getScrollState());
    setScrollWidth(
      scrollbarRef.current?.getScrollState().trackYVisible ? 60 : 0
    );
  }, [scrollbarRef.current?.getScrollState()]);

  return (
    <Scrollbar
      className="custom-scrollbar"
      ref={scrollbarRef}
      wrapperProps={{
        style: {
          right: scrollWidth,
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};
