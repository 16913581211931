import { createSaga } from "../../utils/createSaga";
import {
  hospitalAddRoutine,
  hospitalRoutine,
  hospitalStatusRoutine,
} from "../routines/hospitalRoutine";

export default function* hospitalSaga() {
  yield createSaga(hospitalRoutine, "GET", "api/hospitals");

  yield createSaga(hospitalStatusRoutine, "POST", "api/hospitals/setStatus");

  yield createSaga(hospitalAddRoutine, "POST", "api/hospitals/add");
}
