import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import UserIcon from "../../assets/images/user-ico.svg";
import PasswordIcon from "../../assets/images/password-ico.svg";
import { Input } from "../../components/Input";
import "./style.scss";
import { Button } from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "./schema";
import { useDispatch, useSelector } from "react-redux";
import { userLoginRoutine } from "../../redux/routines/userRoutine";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type Props = {};

export const LoginPage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state: any) => state.user.userLogin);

  const auth = useSelector((state: any) => state.user.auth);
  const userStatus = useSelector((state: any) => state.user.userStatus);

  const [alert, showAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    console.log("userLogin", userLogin);
    if (userLogin.isSuccess) {
      navigate("/");
    }
    if (userLogin.isFailure) {
      setAlertTitle("Ошибка");
      setAlertText(userLogin.errorMessage);
      showAlert(true);
    }
  }, [userLogin]);

  useEffect(() => {
    if (auth.isSuccess && userStatus.isSuccess) {
      navigate("/", { replace: true });
    }
  }, [auth, userStatus]);

  useEffect(() => {
    return () => {
      dispatch(userLoginRoutine.fulfill());
    };
  }, []);

  const handleRegister = useCallback(() => navigate("/register"), [navigate]);
  const onClose = () => showAlert(false);

  return (
    <div className="container" id="LoginPage">
      <div className="logo">
        <img src={Logo} />
        <p className="logo-name">Иду к врачу</p>
      </div>
      <Formik
        initialValues={{ email: "", password: "", role: "admin" }}
        validationSchema={loginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          dispatch(userLoginRoutine(values));
        }}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <Field
              id="email"
              name="email"
              placeholder="Логин (e-mail)"
              component={Input}
              inputProps={{ rightIcon: <img src={UserIcon} /> }}
            />
            <Field
              id="password"
              name="password"
              type="password"
              placeholder="Пароль"
              component={Input}
              inputProps={{ rightIcon: <img src={PasswordIcon} /> }}
            />
            <div className="forgot-row">
              <Link to="/forgot" className="forgot-link">
                Забыли пароль?
              </Link>
            </div>
            <div className="buttons-row">
              <Button type="submit">Войти</Button>
              <Button onClick={handleRegister}>Регистрация</Button>
            </div>
            {Object.values(errors).length > 0 && (
              <div className="form-errors">
                {Object.values(errors).map((msg, index) => (
                  <p key={`err_${index}`}>{msg}</p>
                ))}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <a href="/policy" className="policy-link">
        Политика конфиденциальности
      </a>
      <Dialog
        open={alert}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { overflowY: "unset" } }}
      >
        <div style={{ overflowY: "unset" }}>
          <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alertText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton
              color="primary"
              sx={{
                position: "absolute",
                right: "-20px",
                top: "-20px",
                backgroundColor: "lightgray",
                color: "gray",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
