import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import {
  userRoleStatusRoutine,
  usersGetRoutine,
} from "../../redux/routines/userRoutine";
import { AnamnezItem } from "../Anamnez/AnamnezItem";
import { CustomScrollbar } from "../../components/CustomScrollbar";

type Props = {};

type UserType = {
  [key: string]: any;
};

export const AdminsPage = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user.user);
  const usersGet = useSelector((state: any) => state.user.usersGet);
  const userRoleStatus = useSelector((state: any) => state.user.userRoleStatus);

  const [users, setUsers] = useState<UserType>({});

  const hospitals = useSelector((state: any) => state.hospital.hospitals);

  const [hospitalsData, setHospitalsData] = useState([]);

  const handleStatus = (userId, status) => {
    dispatch(userRoleStatusRoutine({ userId, status }));
  };

  useEffect(() => {
    if (hospitals.isSuccess && hospitals?.data?.items) {
      let hospitalsData: any = {};

      hospitals?.data?.items.map((item) => {
        hospitalsData[item.id] = item.name;
      });

      setHospitalsData(hospitalsData);
    }
  }, [hospitals]);

  useEffect(() => {
    return () => {
      dispatch(usersGetRoutine.fulfill());
    };
  }, []);

  useEffect(() => {
    dispatch(usersGetRoutine({ role: "admin" }));
  }, [userRoleStatus]);

  useEffect(() => {
    if (usersGet.isSuccess) {
      let u = {};
      usersGet.data?.items.map((item) => {
        if (!u[item.status]) u[item.status] = [];
        u[item.status].push(item);
      });
      setUsers(u);
    }
  }, [usersGet]);

  return (
    <div className="block-list">
      <div className="block">
        <h2>Подтверждение регистрации</h2>
        <div className="list">
          <CustomScrollbar>
            {users?.nonactive &&
              users?.nonactive.map((item) => {
                let name = [item.lastname, item.name, item.secondname].join(
                  " "
                );
                return (
                  <AnamnezItem
                    key={`nonactive-${item.id}`}
                    item={{
                      text: `${name}<br>Администратор<br>${
                        hospitalsData[item.hospital]
                      }`,
                    }}
                    rightBtn={
                      <a
                        className="btn-link"
                        onClick={() => handleStatus(item.id, "active")}
                      >
                        Принять
                      </a>
                    }
                  />
                );
              })}
          </CustomScrollbar>
        </div>
      </div>
      <div className="block">
        <h2>Зарегистрированные</h2>
        <div className="list">
          <CustomScrollbar>
            {users?.active &&
              users?.active.map((item) => {
                let name = [item.lastname, item.name, item.secondname].join(
                  " "
                );
                return (
                  <AnamnezItem
                    key={`active-${item.id}`}
                    item={{
                      text: `${name}<br>Администратор<br>${
                        hospitalsData[item.hospital]
                      }`,
                    }}
                    rightBtn={
                      user.data?.id != item.id && (
                        <a
                          className="btn-link"
                          onClick={() => handleStatus(item.id, "deleted")}
                        >
                          Удалить
                        </a>
                      )
                    }
                  />
                );
              })}
          </CustomScrollbar>
        </div>
      </div>
      <div className="block">
        <h2>Удаленные</h2>
        <div className="list">
          <CustomScrollbar>
            {users?.deleted &&
              users?.deleted.map((item) => {
                let name = [item.lastname, item.name, item.secondname].join(
                  " "
                );
                return (
                  <AnamnezItem
                    key={`deleted-${item.id}`}
                    item={{
                      text: `${name}<br>Администратор<br>${
                        hospitalsData[item.hospital]
                      }`,
                    }}
                    rightBtn={
                      <a
                        className="btn-link"
                        onClick={() => handleStatus(item.id, "active")}
                      >
                        Восстановить
                      </a>
                    }
                  />
                );
              })}
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};
