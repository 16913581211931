import * as yup from "yup";

export const registerSchema = yup.object().shape({
  lastname: yup
    .string()
    .required("Заполните фамилию")
    .min(2, "Слишком короткая фамилия"),
  name: yup.string().required("Заполните имя").min(2, "Слишком короткое имя"),
  secondname: yup.string(),
  email: yup
    .string()
    .email("Введите корректный email")
    .required("Заполните email"),

  hospital: yup.string().required("Выберите полклинику"),
  password: yup.string().required("Заполните пароль"),
  confirmPassword: yup
    .string()
    .required("Заполните пароль")
    .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
  agree: yup
    .bool()
    .oneOf([true], "Дайте согласие на обработку персональных данных"),
});
