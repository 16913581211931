import { Checkbox, FormControl, FormControlLabel, Select } from "@mui/material";
import { FieldInputProps, FormikProps } from "formik";
import React from "react";
import Dropdown from "../../assets/images/dropdown-ico.svg";
import "./style.scss";

type Props = {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  inputProps: any;
  type: string;
  children?: JSX.Element;
  style?: any;
  placeholder?: string;
  value?: any;
  ref?: any;
  onChange?: any;
};

export const Input = ({ field, form, inputProps, style, ...props }: Props) => {
  if (props.type == "checkbox") {
    return (
      <div className="checkbox-row" style={{ ...style }}>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...props}
              onChange={(e) => {
                form.setFieldValue(field.name, e.target.checked);
              }}
              icon={<span className="checkbox-custom"></span>}
              checkedIcon={<span className="checkbox-custom checked"></span>}
            />
          }
          label={inputProps.item.name}
        />
      </div>
    );
  }
  if (props.type == "select") {
    return (
      <div className="form-control-row select-row" style={{ ...style }}>
        <Select
          {...field}
          {...props}
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.value);
            props.onChange && props.onChange(e);
          }}
          IconComponent={() => (
            <img src={Dropdown} style={{ position: "absolute", right: 20 }} />
          )}
          sx={{
            backgroundColor: "#fff",
            border: "none",
            width: "100%",
            height: 64,
          }}
          MenuProps={{ sx: { width: "100%" } }}
          displayEmpty
          renderValue={props.value !== "" ? undefined : () => props.placeholder}
        >
          {props.children}
        </Select>
      </div>
    );
  }

  if (props.type == "textarea") {
    return (
      <div className="form-control-row textarea-row" style={{ ...style }}>
        <textarea
          {...field}
          {...props}
          className="form-control"
          autoComplete="new-password"
        />
        {inputProps && inputProps.rightIcon && (
          <div className="form-control-icon">{inputProps.rightIcon}</div>
        )}
      </div>
    );
  }
  return (
    <div className="form-control-row" style={{ ...style }}>
      <input
        ref={props.ref}
        {...field}
        {...props}
        className="form-control"
        autoComplete="new-password"
      />
      {inputProps && inputProps.rightIcon && (
        <div className="form-control-icon">{inputProps.rightIcon}</div>
      )}
    </div>
  );
};
