import classNames from "classnames";
import React, { ReactNode } from "react";
import EditIco from "../../assets/images/edit-ico.svg";
import "./style.scss";

export type Item = {
  title?: string;
  text: string;
};

type Props = {
  item: Item;
  rightBtn?: ReactNode;
  size?: "small";
};

export const AnamnezItem = ({ item, rightBtn, size }: Props) => {
  const { title, text } = item;
  return (
    <div
      className={classNames("anamnez-card", {
        "anamnez-cardSmall": size === "small",
      })}
    >
      {rightBtn}
      <p
        className={classNames("anamnez-cardText", {
          "anamnez-cardTextSmall": size == "small",
        })}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></p>
    </div>
  );
};
