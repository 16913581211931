import * as yup from "yup";
export const hospitalSchema = yup.object().shape({
  glavLastname: yup.string().required("Заполните фамилию"),
  glavName: yup.string().required("Заполните имя"),
  glavSecondname: yup.string(),
  name: yup.string().required("Заполните название"),
  region: yup.string().required("Заполните регион"),
  city: yup.string().required("Заполните город"),
  street: yup.string().required("Заполните улицу"),
  house: yup.string().required("Заполните дом"),
  korpus: yup.string(),
  stroenie: yup.string(),
});
