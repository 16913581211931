import classNames from "classnames";
import React from "react";
import "./style.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "yellow";
}

export const Button = ({ variant, children, ...props }: Props) => {
  return (
    <button
      {...props}
      className={classNames("site-btn", { [`${variant}`]: variant })}
    >
      {children}
    </button>
  );
};
