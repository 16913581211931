import {
  visitSaveRoutine,
  visitCommentsRoutine,
} from "./../routines/visitsRoutine";
import { combineReducers } from "redux";

import { createPersistedReducer } from "../../utils/createPersistedReducer";
import { createReducer } from "../../utils/createReducer";
import { visitsRoutine, visitsAddRoutine } from "../routines/visitsRoutine";

const persistConfig = {
  key: "visits",
  blacklist: ["visits", "visitsAdd", "visitSave", "visitComments"],
};

export default createPersistedReducer(
  combineReducers({
    visits: createReducer(visitsRoutine, "visits"),
    visitsAdd: createReducer(visitsAddRoutine, "visitsAdd"),
    visitSave: createReducer(visitSaveRoutine, "visitSave"),
    visitComments: createReducer(visitCommentsRoutine, "visitComments"),
  }),
  persistConfig
);
