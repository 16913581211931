import { get } from "lodash";
import { REHYDRATE } from "redux-persist";

import RemoteData from "./RemoteData";

export const createReducer = (routine: any, hydratePath = "", useCache = false) => {
	const initialState = new RemoteData({ useCache });

	const reducer = (state = initialState, action: any) => {
		switch (action.type) {
			case routine.FULFILL:
				return new RemoteData({ useCache });
			case routine.SUCCESS:
				return state.success(action.payload);
			case routine.FAILURE:
				return state.failure(action.payload);
			case routine.REQUEST:
				return state.request();
			case REHYDRATE:
				if (hydratePath && get(action.payload, hydratePath)) {
					return new RemoteData({
						...get(action.payload, hydratePath),
						useCache,
					});
				}
				return state;
			default:
				return state;
		}
	};
	return reducer;
};
